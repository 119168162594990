<template>
  <el-dialog
    v-el-drag-dialog
    append-to-body
    :visible.sync="dialogVisible"
    :title="getTitle()"
    class="small-padding"
    width="800px"
    :modal="false"
    top="3vh">
    <vm-table
      ref="vmTable"
      :filter.sync="filter"
      url="eventTemplates">
      <template slot="adSearch">
        <div class="vm-search">
          <vm-search label="模板名称">
            <el-input v-model.trim="filter.name" clearable></el-input>
          </vm-search>
        </div>
      </template>
      <el-table-column align="center" type="index" width="50"></el-table-column>
      <el-table-column prop="name" label="模板名称" align="center"></el-table-column>
      <el-table-column prop="note" label="备注" align="center"></el-table-column>
      <el-table-column label="操作" align="center" width="80">
        <template slot-scope="scope">
          <el-button type="primary" plain @click="onSelect(scope.row)">选择</el-button>
        </template>
      </el-table-column>
    </vm-table>
  </el-dialog>
</template>
<script>
  export default {
    components: {},
    data() {
      return {
        dialogVisible: false,
        filter: {
          name: "",
          type: "",
        },
      };
    },
    methods: {
      open(type) {
        this.dialogVisible = true;
        this.filter.type = type;
        this.$nextTick(() => {
          this.$refs.vmTable.getList(1);
        });
      },
      onSelect(row) {
        this.dialogVisible = false;
        this.$emit("select", row);
      },
      getTitle() {
        if (this.filter.type === "fault") {
          return "选择故障模板";
        } else if (this.filter.type === "event") {
          return "选择事件模板";
        } else {
          return "选择模板";
        }
      },
    },
  };
</script>
<style lang="scss" scoped>
</style>
