<template>
  <el-dialog
    v-el-drag-dialog
    :visible.sync="dialogVisible"
    :close-on-click-modal="false"
    :title="model.name+' 代码列表'"
    width="850px"
    :modal="false"
    top="3vh"
    @close="$reset('form')">
    <div style="margin-bottom: 10px">
      <el-button v-if="!model.buildIn" type="primary" @click="addRow">新增一行</el-button>
      <el-button v-if="!model.buildIn" type="primary" @click="$refs.eventTemplateSelect.open()">从其他模板复制</el-button>
    </div>
    <el-table
      ref="vmTable"
      v-loading="contentLoading"
      border
      :data="model.items">
      <el-table-column label="行号" align="center" type="index" width="50"></el-table-column>
      <el-table-column prop="code" label="代码" align="center" width="120">
        <template slot-scope="scope">
          <el-input v-model="scope.row.code"></el-input>
        </template>
      </el-table-column>
      <el-table-column prop="name" label="名称" align="center">
        <template slot-scope="scope">
          <el-input v-model="scope.row.name"></el-input>
        </template>
      </el-table-column>
      <el-table-column prop="level" label="等级" align="center" width="80">
        <template slot-scope="scope">
          <el-input v-model="scope.row.level" class="textCenter"></el-input>
        </template>
      </el-table-column>
      <el-table-column
        v-if="!model.buildIn"
        label="操作"
        align="center"
        width="200px">
        <template slot-scope="scope">
          <el-button type="danger" @click="model.items.splice(scope.$index, 1);">删除</el-button>
          <el-button type="primary" @click="$util.up(model.items,scope.$index)">上移<i class="el-icon-top"></i></el-button>
          <el-button type="primary" @click="$util.down(model.items,scope.$index)">下移<i class="el-icon-bottom"></i></el-button>
        </template>
      </el-table-column>
    </el-table>
    <div slot="footer">
      <el-button @click="dialogVisible=false">取消</el-button>
      <el-button type="primary" @click="save">保存</el-button>
    </div>
    <event-template-select ref="eventTemplateSelect" @select="copyFrom"></event-template-select>
  </el-dialog>
</template>
<script>
  import EventTemplateSelect from "@/views/eventTemplate/Select";

  export default {
    components: {EventTemplateSelect},
    data() {
      return {
        dialogVisible: false,
        contentLoading: true,
        submitLoading: false,
        model: {
          id: null,
          name: "",
          buildIn: false,
          items: [],
        },
      };
    },
    methods: {
      open(model) {
        this.$assign(this.model, model);
        this.dialogVisible = true;
        this.getData(this.model.id);
      },
      getData(id) {
        this.contentLoading = true;
        this.$http.get(`eventTemplates/${id}`).then(data => {
          this.contentLoading = false;
          this.model.items = data.items || [];
        });
      },
      addRow() {
        this.model.items.push({
          code: "",
          name: "",
          level: "",
        });
      },
      save() {
        this.submitLoading = true;
        this.$http.put(`eventTemplates/${this.model.id}/updateItems`, this.model.items).then(() => {
          this.submitLoading = false;
          this.dialogVisible = false;
          this.$message.success("保存成功");
        }).then(() => {
          this.submitLoading = false;
        });
      },
      copyFrom(row) {
        this.getData(row.id);
      },
    },
  };
</script>
<style lang="scss" scoped>
::v-deep .textCenter {
  input {
    text-align: center;
  }
}
</style>
